<template>
  <div class="auth-content full-width-padding">
    <h1 class="auth">Focus on the Harvest</h1>
    <div class="admin-control-panel">
      <admin-control-card title="Nyheter" subtext="Skapa, redigera och ta bort nyhetsinlägg." color="#269400" route="news" />
      <admin-control-card title="Kalender" subtext="Skapa, redigera och ta bort händelser ur kalendern." color="#00349A" route="calendar" />
      <admin-control-card title="Videobibliotek" subtext="Skapa, redigera och ta bort videosamlingar. Hantera videos." color="#D09600" route="videos" />
      <!-- <admin-control-card title="Bibelord" subtext="Ändra bibelord på framsidan." color="#Ef41b2" route="word" /> -->
    </div>
  </div>
</template>

<script>
import AdminControlCard from '../../components/admin/AdminControlCard.vue'

export default {
  components: {
    AdminControlCard,
  },
  metaInfo() {
    return {
      title: 'Admin'
    }
  },
}
</script>

<style scoped>
.auth-content {
  display: flex;
  flex-direction: column;
}
.admin-control-panel {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
  column-gap: 15px;
  row-gap: 15px;
  justify-content: flex-start;
}

</style>