<template>
  <router-link :to="route" append>
    <div class="admin-control-card">
      <div class="admin-control-card-header" :style="{backgroundColor: color}">
        <h3 class="auth">{{ title }}</h3>
      </div>
      <p class="auth" style="text-align: left;">{{ subtext }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    title: '',
    subtext: '',
    color: '',
    route: '',
  }
}
</script>

<style>
.admin-control-card {
  height: 180px;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
  cursor: pointer;
  transition: transform 100ms ease-out, box-shadow 100ms ease-out;
}
.admin-control-card:hover {
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.075);
  transform: translateY(2px);
}

.admin-control-card-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: green;
  color: white;
  box-sizing: border-box;
  height: 40px;
}
.admin-control-card h3 {
}
</style>